<script type="text/ecmascript-6">
import charge from "./charge";
import { tweetList } from '../utils/content.js';
import '../utils/charge.css'; // 引入样式文件

export default {
  name: 'TweetPage',
  extends: charge,
  components: {},
  data() {
    return {
      advList: tweetList
    }
  },
  created() {
    window.scrollTo({
      top: 0,
      behavior: 'auto'
    });
  },
}
</script>
    